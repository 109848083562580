@import url("https://fonts.googleapis.com/css?family=DM+Sans:500&display=swap");

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  background: #fffcf2;
  font-family: "DM Sans", sans-serif;
}

canvas,
#ui {
  display: block;
  width: 55.5vh;
  height: 100vh;
  /* height:width ratio = 9/5  */
  background: #fffcf2;
  max-height: 180vw;
  max-width: 100vw;
  /* 5/9 */
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  /* vertical center */
  left: 0;
  right: 0;
  /* horizontal center */
  animation: fade-in 1s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#ui {
  position: absolute;
  z-index: 10;
  background: none;
  pointer-events: none;
  user-select: none;
}

#score {
  position: absolute;
  top: 2%;
  left: 2%;
  font-size: 200%;
  color: #cccccc;
  pointer-events: none;
}

#endgame,
#menu {
  width: 100%;
  height: 100%;
}

#menu > #flyck {
  margin: auto;
  text-align: center;
  font-size: 643%;
  margin-top: 40%;
}

#menu > .buttons {
  height: 30%;
}

.buttons {
  width: 60%;
  height: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
}

.buttons > .message {
  font-size: 2em;
  text-align: center;
  margin-top: 5em;
}

.buttons > .message > span {
  font-size: 3em;
}

button#play-again {
  margin-top: 3em;
}

.buttons > button {
  margin: auto;
  width: 100%;
  max-width: 10em;
  border-radius: 2rem;
  height: 4rem;
  border: solid #cccccc 0.1rem;
  font-size: 3vh;
  background: #fed766;
  font-family: "DM Sans", sans-serif;
  color: #000000ad;
  pointer-events: all;
  outline: none;
  cursor: pointer;
}

#version {
  text-align: center;
  font-size: 0.5em;
  opacity: 0.5;
}
